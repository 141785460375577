.block-headline {
    overflow-wrap: break-word;
    max-width: var(--width-main);
    margin: 0 auto;
    /*padding: var(--block-space-vertical-small) var(--block-space-horizontal);*/

    padding-left: var(--block-space-horizontal);
    padding-right: var(--block-space-horizontal);
}

.block-headline * {
    display: inline;
    border-bottom: .25rem var(--color-yellow) solid;
    line-height: 2;
    padding-bottom: .25rem;
}

.block-headline.center * {
    border-bottom: .25rem var(--color-yellow) solid;
    line-height: 2;
    padding-bottom: .25rem;
}
.block-headline.center {
    text-align: center;
    padding-left: 3rem;
    padding-right: 3rem;
}

@media (min-width: 600px) {  /* <- 600px */
    .block-headline.center * {
        border-bottom: .25rem var(--color-yellow) solid;
        line-height: 2;
        padding-bottom: .25rem;
        text-align: center !important;
    }
    .block-headline.center {
        text-align: center !important;
    }
}